import React from 'react'
import FermentadorCard from './FermentadorCard'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';

const TableroCard = ({ data }) => {

  let arregloData = []

  Object.entries(data).forEach(([key, value]) => {
    if (key !== 'fechaFormato' && key !== 'of' && key !== 'chart')
      arregloData.push(value)
  });



  return (

    <Box sx={{ flexGrow: 1 }}>
      <div style={{ marginBottom: '10px', borderRadius: '10px', backgroundColor: 'white', boxShadow: '0px 0px 10px black' }}>
        <Typography variant='h6'>INFORMACION</Typography>
        <Typography variant='body1'>OF {data.of}</Typography>
        <Typography variant='body1'>{data.fechaFormato}</Typography>
      </div>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        {arregloData.map((value, index) => (
          <Grid item xs={12} sm={8} md={4} key={index}>
            <FermentadorCard data={value} numerofermentador={index + 1} chart={data.chart} numeroof={data.of} />
          </Grid>

        ))}
      </Grid>
      <Divider light={true} style={{ paddingTop: '10px' }}>
        <Chip style={{ color: 'white', border: 'solid 2px white' }} label={data.of} />
      </Divider>
    </Box>

    // </div>
  )
}

export default TableroCard