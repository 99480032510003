import React, { useState } from 'react'
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import AcUnitRoundedIcon from '@mui/icons-material/AcUnitRounded';
import PowerSettingsNewRoundedIcon from '@mui/icons-material/PowerSettingsNewRounded';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useAuth } from "../context/AuthContext";
import { db } from "../firebase";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import GraficaCard from './GraficaCard';




const FermentadorCard = ({ data, numerofermentador, chart, numeroof }) => {

  const [open, setOpen] = useState(false)
  const [nombre, setNombre] = useState(data.nombre)
  const [tempSet, setTempSet] = useState(data.temperaturaset)
  const [modo, setModo] = useState(data.modo === "0" ? false : true)
  const [inicio, setInicio] = useState(data.inicio === "0" ? false : true)

  const { user } = useAuth();

  const uid = user.uid;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleActualizacion = () => {

    db.ref()
      .child("usuarios")
      .child(uid)
      .child("FERMENTADORES")
      .child(numeroof)
      .child("actualizacion")
      .set(`${tempSet},${modo === false ? '0' : '1'},${inicio === false ? '0' : '1'},${nombre},${numerofermentador}`)

    setOpen(false);
  };


  return (


    <div style={{ borderRadius: '10px' }}>
      <div style={{ borderRadius: '10px', backgroundColor: 'white', boxShadow: '0px 0px 10px black' }}>
        <Typography variant='h6'>IDENTIFICACION</Typography>
        <Typography variant='body1'>{data.nombre}</Typography>
      </div>

      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

        <Grid item xs={6} >
          <div style={{ marginTop: '10px', borderRadius: '10px', backgroundColor: 'white', boxShadow: '0px 0px 10px black' }}>
            <Typography variant='body1'>Temperatura Actual</Typography>
            <CircularProgressbar value={data.temperaturaactual} text={`${data.temperaturaactual}°C`} styles={buildStyles({ textColor: '#FF3E00', pathColor: '#FF3E00', trailColor: 'grey' })} />
          </div>

        </Grid>
        <Grid item xs={6}>
          <div style={{ marginTop: '10px', borderRadius: '10px', backgroundColor: 'white', boxShadow: '0px 0px 10px black' }}>
            <Typography variant='body1'>Temperatura Set</Typography>
            <CircularProgressbar value={data.temperaturaset} text={`${data.temperaturaset}°C`} styles={buildStyles({ textColor: '#1984EB', pathColor: '#1984EB', trailColor: 'grey' })} />
          </div>
        </Grid>
      </Grid>

      <div style={{ marginTop: '10px', borderRadius: '10px', backgroundColor: 'white', boxShadow: '0px 0px 10px black' }}>
        <Typography variant='h6'>ESTADO</Typography>

        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

          <Grid item xs={6} >
            <PowerSettingsNewRoundedIcon fontSize='large' style={{ color: data.inicio === '1' ? 'green' : 'grey' }}></PowerSettingsNewRoundedIcon>
          </Grid>

          <Grid item xs={6}>
            {data.modo === '0' ?
              <AcUnitRoundedIcon fontSize='large' style={{ color: data.estadosolenoide === '1' ? '#1984EB' : 'grey' }}></AcUnitRoundedIcon>
              :
              <LocalFireDepartmentIcon fontSize='large' style={{ color: data.estadosolenoide === '1' ? '#FF3E00' : 'grey' }}></LocalFireDepartmentIcon>}

          </Grid>

        </Grid>
      </div>

      <GraficaCard numerofermentador={numerofermentador} chart={chart} />

      <div style={{ marginTop: '10px', borderRadius: '10px', backgroundColor: 'white', boxShadow: '0px 0px 10px black' }}>

        <Typography variant='h6'>PANEL DE CONTROL</Typography>
        <IconButton color="primary" aria-label="settings" component="label" onClick={handleClickOpen}>
          <SettingsIcon fontSize='large' style={{ color: '#1E1E1E' }} />
        </IconButton>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle textAlign="center">Parametros Fermentador #{numerofermentador}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Seleccione los parametros a modificar
            </DialogContentText>
            <TextField
              autoFocus
              defaultValue={data.nombre}
              onChange={(event) => {
                setNombre(event.target.value);
              }}
              margin="dense"
              id="nombre"
              label="Nombre"
              type="text"
              fullWidth
              variant="standard"
            />
            <TextField
              autoFocus
              defaultValue={data.temperaturaset}
              onChange={(event) => {
                setTempSet(event.target.value);
              }}
              margin="dense"
              id="tempset"
              label="Set"
              type="text"
              fullWidth
              variant="standard"
            />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <FormControlLabel style={{ color: modo === false ? '#1984EB' : '#FF3E00' }} control={<Switch style={{ color: modo === false ? '#1984EB' : '#FF3E00' }} checked={modo} onChange={(event) => { setModo(event.target.checked) }} />} label={modo === false ? 'MODO FRIO' : 'MODO CALOR'} />
              <FormControlLabel style={{ color: inicio === false ? '#FF3E00' : 'green' }} control={<Switch style={{ color: inicio === false ? '#FF3E00' : 'green' }} checked={inicio} onChange={(event) => { setInicio(event.target.checked) }} />} label={inicio === false ? 'APAGADO' : 'ENCENDIDO'} />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={handleActualizacion}>Actualizar</Button>
          </DialogActions>
        </Dialog>

      </div>

    </div>
  )
}

export default FermentadorCard