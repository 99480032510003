import { useContext } from "react";
import { dataContext } from "../context/DataContext";
import ResponsiveAppBar from "./ResponsiveAppBar";
import TableroCard from "./TableroCard";
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Loading from "./Loading";
import { Typography } from '@mui/material';
import logoPagina from '../assets/pagina.png';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


export function Home() {

  const { data, obtainData } = useContext(dataContext);

  return (
    <>
      <ResponsiveAppBar />

      {(obtainData === false && data.length !== 0) ?
        <div>
          <Box sx={{ width: '100%' }}>
            <Grid container justifyContent="center" alignItems="center" rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              {data.map((fermentador, index) => (
                <Grid item xs={12} key={index}>
                  <Item sx={{ borderRadius: '10px', backgroundColor: '#5A5E5F', boxShadow: '0px 0px 0px black', marginY: '10px' }}><TableroCard data={fermentador} /></Item>
                </Grid>
              ))}
            </Grid>
          </Box>
          <div style={{ textAlign: 'center' }}>
            <img style={{ height: '40px', }} src={logoPagina} alt="" />
            <Typography style={{ padding: '5px' }} variant='body2' color='#1E1E1E'>Developed by Medelinox ®</Typography>
          </div>
        </div>

        : (obtainData === false && data.length === 0) ?
          <div>
            <div style={{ textAlign: 'center', padding: '50px' }}>
              <Typography style={{ padding: '5px' }} variant='body1' color='#1E1E1E'>No se han encontrado dispositivos asociados a su cuenta</Typography>
            </div>
            <div style={{ textAlign: 'center' }}>
              <img style={{ height: '40px', }} src={logoPagina} alt="" />
              <Typography style={{ padding: '5px' }} variant='body2' color='#1E1E1E'>Developed by Medelinox ®</Typography>
            </div>
          </div>
          :
          <Loading />
      }
    </>
  );
}
