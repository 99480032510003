import firebase from 'firebase/compat/app'
import {getAuth} from 'firebase/auth'
import 'firebase/compat/database'

const firebaseConfig = {
  apiKey: "AIzaSyAIQlm3bsmOwrNTckH196ysyufWRRqtpw0",
  authDomain: "medelinox-app.firebaseapp.com",
  databaseURL: "https://medelinox-app-default-rtdb.firebaseio.com",
  projectId: "medelinox-app",
  storageBucket: "medelinox-app.appspot.com",
  messagingSenderId: "63092229532",
  appId: "1:63092229532:web:fa31ffffa50786ddc9b459",
  measurementId: "G-31XC6YQZRH"
};
  
  // Initialize Firebase
export const app = firebase.initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = app.database();

