import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import logoPagina from '../assets/pagina.png';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';


export function Login() {

  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const { login } = useAuth();
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await login(user.email, user.password);
      navigate("/");
    } catch (error) {
      setError(error.message);
    }
  };

  const handleChange = ({ target: { value, name } }) =>
    setUser({ ...user, [name]: value });

  return (

    <div style={{ backgroundColor: '#1E1E1E', padding: '0px', margin: '0px', height: '100vh', width: '100vw', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

      <div style={{ borderRadius: '10px', backgroundColor: 'white', boxShadow: '0px 0px 5px white', padding: '20px', width: '300px' }}>
        <div style={{ textAlign: 'center' }}>
          <Typography variant='h5' style={{ color: '#1E1E1E' }}>Login</Typography>
        </div>

        {error && <Typography variant='body2' style={{ color: 'red', textAlign: 'center' }}>{error.substring(10)}</Typography>}

        <form
          onSubmit={handleSubmit}
        >
          <div>
            <TextField style={{ width: '100%', margin: '5px' }}
              color='warning'
              variant="standard"
              name="email"
              id="email"
              label="Email"
              type="email"
              onChange={handleChange}
              placeholder='micorreo@ejemplo.com'
            />
          </div>
          <div>
            <TextField style={{ width: '100%', margin: '5px' }}
              color='warning'
              variant="standard"
              name="password"
              id="password"
              label="Password"
              type="password"
              autoComplete="current-password"
              onChange={handleChange}
              placeholder='***********'
            />
          </div>

          <div className="flex items-center justify-between">
            <div style={{ textAlign: 'center', margin: '10px' }}>
              <Button type="submit" variant="outlined" style={{ color: '#FF3E00', border: '#FF3E00 solid 1px' }}>Ingresar</Button>
            </div>
          </div>
        </form>
      </div>
      <div style={{ position: 'absolute', bottom: '0px', textAlign: 'center' }}>
        <img style={{ height: '40px', }} src={logoPagina} alt="" />
        <Typography style={{ padding: '5px' }} variant='body2' color='white'>Developed by Medelinox ®</Typography>
      </div>

    </div>
  );
}