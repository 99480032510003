import React, { useState } from 'react'
import { Typography } from '@mui/material';
import 'react-circular-progressbar/dist/styles.css';
import { Chart as ChartJS } from 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import IconButton from '@mui/material/IconButton';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { Link } from "react-router-dom";

const GraficaCard = ({ numerofermentador, chart }) => {

  const [filtroFecha, setFiltroFecha] = useState('todos')

  const handleFiltroFechas = (event, selectFecha) => {
    setFiltroFecha(selectFecha)
  };


  let tempActualChart = []
  let tempSetChart = []
  let fechasChart = []
  let inicioChart = []
  let solenoideChart = []

  let fechaActual = new Date()
  let fechaFiltro = new Date()

  switch (filtroFecha) {
    case 'unahora':
      fechaFiltro.setHours(fechaActual.getHours() - 1)
      break;
    case 'undia':
      fechaFiltro.setDate(fechaActual.getDate() - 1)
      break;
    case 'unasemana':
      fechaFiltro.setDate(fechaActual.getDate() - 7)
      break;
    case 'unmes':
      fechaFiltro.setDate(fechaActual.getDate() - 30)
      break;

    default:
      break;
  }


  for (let index = 0; index < chart.length; index++) {

    if (filtroFecha === 'todos') {

      tempActualChart.push(chart[index][numerofermentador]['temperaturaactual'])
      tempSetChart.push(chart[index][numerofermentador]['temperaturaset'])

      if (chart[index][numerofermentador]['estadosolenoide'] === '0') {
        solenoideChart.push(60)
      } else {
        solenoideChart.push(100)
      }

      if (chart[index][numerofermentador]['inicio'] === '0') {
        inicioChart.push(0)
      } else {
        inicioChart.push(40)
      }
      fechasChart.push(chart[index]['fechaFormato'].substring(0, 16))

    } else {

      if (new Date(chart[index]['fechaFormato']) >= fechaFiltro) {

        tempActualChart.push(chart[index][numerofermentador]['temperaturaactual'])
        tempSetChart.push(chart[index][numerofermentador]['temperaturaset'])

        if (chart[index][numerofermentador]['estadosolenoide'] === '0') {
          solenoideChart.push(60)
        } else {
          solenoideChart.push(100)
        }

        if (chart[index][numerofermentador]['inicio'] === '0') {
          inicioChart.push(0)
        } else {
          inicioChart.push(40)
        }
        fechasChart.push(chart[index]['fechaFormato'].substring(0, 16))

      }
    }
  }

  const chartDataTemp = {
    labels: fechasChart,
    datasets: [
      {
        label: "Temperatura Actual",
        data: tempActualChart,
        backgroundColor: [
          "white"
        ],
        borderColor: "#FF3E00",
        borderWidth: 2,
        pointStyle: false,
      },
      {
        label: "Temperatura Set",
        data: tempSetChart,
        backgroundColor: [
          "white"
        ],
        borderColor: "#1984EB",
        borderWidth: 2,
        pointStyle: false,
      },
    ],
  }

  const chartDataProceso = {
    labels: fechasChart,
    datasets: [
      {
        label: "Recirculacion",
        data: solenoideChart,
        backgroundColor: [
          "white"
        ],
        borderColor: "#FF3E00",
        borderWidth: 2,
        pointStyle: false,
      },
      {
        label: "Proceso",
        data: inicioChart,
        backgroundColor: [
          "white"
        ],
        borderColor: "#1984EB",
        borderWidth: 2,
        pointStyle: false,
      },

    ],
  }


  return (
    <div style={{ marginTop: '10px', borderRadius: '10px', backgroundColor: 'white', boxShadow: '0px 0px 10px black' }}>
      <Typography variant='h6' style={{ textAlign: 'center' }}>CENTRO DE DATOS</Typography>
      <Line data={chartDataTemp}></Line>
      <Line data={chartDataProceso}></Line>
      <div style={{ textAlign: 'center' }}>
        <ToggleButtonGroup
          style={{ padding: '10px' }}
          color="warning"
          value={filtroFecha}
          exclusive
          onChange={handleFiltroFechas}
          aria-label="Platform"
        >
          <ToggleButton value="todos">TODOS</ToggleButton>
          <ToggleButton value="unmes">1M</ToggleButton>
          <ToggleButton value="unasemana">1S</ToggleButton>
          <ToggleButton value="undia">1D</ToggleButton>
          <ToggleButton value="unahora">1H</ToggleButton>
        </ToggleButtonGroup>
      </div>

      <div style={{ textAlign: 'center' }}>
        <Link to="/grafica" state={{ chart: chart, numerofermentador: numerofermentador }}>
          <IconButton aria-label="" component="label">
            < OpenInFullIcon />
          </IconButton>
        </Link>

      </div>
    </div>
  )
}

export default GraficaCard