import React from 'react'
import ResponsiveAppBar from './ResponsiveAppBar'
import { useLocation } from 'react-router-dom'
import GraficaCard from './GraficaCard'

const GraficaScreen = () => {

  const location = useLocation()
  const { chart, numerofermentador } = location.state



  return (

    <>
      <ResponsiveAppBar />
      <div style={{ padding: '10px' }}>
        <GraficaCard chart={chart} numerofermentador={numerofermentador} />
      </div>
    </>


  )
}

export default GraficaScreen